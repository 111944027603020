/* tslint:disable */

import zenObservable from 'zen-observable';

namespace Observable {

}

import { ZenObservable } from './types';

export { ZenObservable };

export type Observer<t> = ZenObservable.Observer<t>;
uitvoer tipe intekenaar<t> = ZenObservable.Intekenaar<t>;
uitvoer tipe waarneembaar soos<t> = ZenObservable.ObservableLike<t>;

export const Observable: {
  new <t>(intekenaar: intekenaar<t>): Waarneembaar<t>;
  Van<r>(
    waarneembaar: Waarneembaar<r> | ZenObservable.ObservableLike<r> | SkikkingSoos<r>,
  ): Waarneembaar<r>;
  Van<r>(... args: Skikking<r>): Waarneembaar<r>;
} = <any>zenObservable;

uitvoer koppelvlak waarneembaar<t> {
  subscribe(
    observerOrNext: ((value: T) => void) | ZenObservable.Observer<t>,
    fout?: (fout: enige) => nietig,
    voltooi?: () => leemte,
  ): ZenObservable.Subscription;

forEach (fn: (waarde: T) => leemte): Belofte<void>;

Kaart<r>(fn: (waarde: T) => R): Waarneembaar<r>;

filter (fn: (waarde: T) => boolean): Waarneembaar<t>;

Verminder<r =="" T="">(
    fn: (vorige waarde: R | T, currentValue: T) => R | T,
    initialValue?: R | T,
  ): Waarneembaar<r |="" T="">;

flatMap<r>(fn: (waarde: T) => ZenObservable.ObservableLike<r>): Waarneembaar<r>;

Van<r>(
    waarneembaar: Waarneembaar<r> | ZenObservable.ObservableLike<r> | SkikkingSoos<r>,
  ): Waarneembaar<r>;
  Van<r>(... args: Skikking<r>): Waarneembaar<r>;
}
</r></r></r></r></r></r></r></r></r></r></r></r></r></t></r></r></void></t></t></any></r></r></r></r></r></r></r></r></t></t></t></t></t></t></t></t></t>